<template>
    <div>
        <Dialog
            :visible.sync="showDialog"
            header=""
            position="center"
            :modal="true"
            :closeOnEscape="true"
            :closable="true"
            @update:visible="closeDialog"
        >
            <template #header>
                <h3 class="p-mb-0">Информация о подписи</h3>
            </template>
            <div class="p-d-flex p-flex-column p-col-12 p-py-1 p-mb-0 p-px-0">
                <label>ФИО: {{ signInfo.signedBy.lastName }} {{ signInfo.signedBy.firstName }} {{ signInfo.signedBy.secondName }}</label>
            </div>
            <div v-if="signInfo.signedOrganization" class="p-d-flex p-flex-column p-col-12 p-py-1 p-mb-0 p-px-0">
                <label>Организация: {{ signInfo.signedOrganization.attributes.shortName }}</label>
            </div>
            <div class="p-d-flex p-flex-column p-col-12 p-py-1 p-mb-0 p-px-0">
                <label>Сертификат: {{ signInfo.signatureInfo.certificate }}</label>
            </div>
            <div class="p-d-flex p-flex-column p-col-12 p-py-1 p-mb-0 p-px-0">
                <label>Дата подписи: {{ signInfo.signedAt | dateTime }}</label>
            </div>
            <template #footer>
                <div class="p-d-flex p-jc-start">
                    <Button class="p-button p-button-outlined p-button-secondary" type="button" @click="closeDialog">
                        <span class="p-button-label">Закрыть</span>
                    </Button>
                </div>
            </template>
        </Dialog>
    </div>
</template>

<script>
import { requestToastHandler } from '@/main/mixins';
import { mapGetters } from 'vuex';

export default {
    name: 'signInfoDialog',

    props: {
        showSignInfoDialog: {
            require: true,
            type: Boolean
        },

        signInfo: {
            type: Object,
            require: true
        }
    },

    mixins: [ requestToastHandler ],

    emits: [ 'closeSignInfoDialog'],

    data() {
        return {
            showDialog: this.showSignInfoDialog,
        };
    },

    methods: {
        closeDialog() {
            this.showDialog = false;
            this.submitted = false;
            this.$emit('closeSignInfoDialog');
        }
    },

    computed: {
        ...mapGetters('auth', [ 'currentUser' ])
    },

    watch: {
        showSignInfoDialog: {
            handler(to) {
                if (to) {
                    this.showDialog = this.showSignInfoDialog;
                }
            }
        }
    }
}
</script>

<style scoped lang="scss">
::v-deep .p-dialog {
    min-width: 400px;
}
</style>
