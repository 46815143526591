<template>
    <div class="p-col-12 p-card relative page-container">
        <div class="p-datatable-loading-overlay p-component-overlay p-d-flex p-ai-center p-jc-center" v-if="loading">
            <img src="@/assets/img/spiner-loader.png" alt="loading" />
        </div>
        <DataTable
            v-if="reports"
            :value="reports"
            class="p-datatable-customers p-datatable-striped user-list"
            :scrollable="true"
            :paginator="true"
            :lazy="true"
            scroll-height="calc(100vh - 350px)"
            :class="{ showFilters: showFilters }"
            :rows="pageSize"
            :rowsPerPageOptions="rowsPerPageOptions"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate=""
            :totalRecords="totalRecords"
            dataKey="id"
            selectionMode="single"
            :filters="filters"
            filterMode="lenient"
            stripedRows
            resizable-columns
            @page="onPage"
            @sort="onSort"
            @row-dblclick="rowDblClickHandler"
        >
            <template #header>
                <div class="table-header p-flex-wrap p-d-flex">
                    <div class="p-col-8 p-p-0 p-d-flex p-ai-center">
                        <h5 class="p-d-inline p-mb-0 p-pt-0">Выгрузки</h5>
                    </div>
                    <div class="p-col-5 p-p-0 p-d-flex p-ai-center"></div>
                    <div class="p-col-7 p-p-0 p-d-flex p-ai-center p-jc-end table-global-search">
                        <div class="p-inputgroup p-p-0 p-d-flex table-global-search__input">
                            <span class="p-float-label">
                                <InputText
                                    type="text"
                                    v-model="filters.name"
                                    placeholder="Поиск"
                                    @keydown="selectedFilter"
                                />
                            </span>
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-search"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </template>
            <template #empty>Выгрузки не найдены.</template>
            <template #loading>Список выгрузок загружается. Пожалуйста, подождите.</template>
            <Column
                header="Форма"
                field="form"
                header-class="user-header-user table-header"
                body-class="user-body-user table-body"
            >
                <template #body="slotProps">
                    <div class="p-text-nowrap p-text-truncate">
                        {{ slotProps.data.form }}
                    </div>
                </template>
                <template #filter v-if="showFilters">
                    <Dropdown
                        v-model="filters.form"
                        placeholder="Поиск"
                        :options="formList"
                        option-label="name"
                        data-key="id"
                        class="customDropDown p-column-filter"
                        filter
                        @change="selectedFilter"
                    />
                </template>
            </Column>
            <Column
                header="Пользователь"
                field="user"
                header-class="user-header-user table-header"
                body-class="user-body-user table-body"
            >
                <template #body="slotProps">
                    <div class="p-text-nowrap p-text-truncate">
                        {{ slotProps.data.user }}
                    </div>
                </template>
                <template #filter v-if="showFilters">
                    <Dropdown
                        v-model="filters.user"
                        placeholder="Поиск"
                        :options="userList"
                        data-key="id"
                        option-label="option"
                        class="customDropDown p-column-filter"
                        filter
                        :showClear="true"
                        @filter="debouncedUserFilter"
                        @change="selectedFilter"
                    />
                </template>
            </Column>
            <Column
                header="Выгрузка"
                field="status"
                header-class="user-header-user table-header"
                body-class="user-body-user table-body"
            >
                <template #body="slotProps">
                    <span :style="`background: ${ getStatus(slotProps).bgColor }; padding: 4px; border-radius: 4px;`">
                        {{ getStatus(slotProps).label }}
                    </span>
                </template>
                <template #filter v-if="showFilters">
                    <Dropdown
                        v-show="showFilters"
                        v-model="filters.status"
                        :options="reportStatuses"
                        option-label="label"
                        data-key="value"
                        placeholder="Поиск"
                        class="customDropDown p-column-filter"
                        :showClear="true"
                        @change="selectedFilter"
                    />
                </template>
            </Column>
            <Column
                header="Дата начала"
                field="blame.createdAt"
                header-class="user-header-date table-header"
                body-class="user-body-date table-body"
                :sortable="true"
            >
                <template #body="slotProps">
                    <div class="p-text-nowrap p-text-truncate">
                        {{ slotProps.data.createdAt | dateTime }}
                    </div>
                </template>
                <template #filter v-if="showFilters">
                    <Calendar
                        id="createdAt"
                        v-model="createdAt"
                        :showIcon="true"
                        :showOnFocus="true"
                        showButtonBar
                        dateFormat="dd.mm.yy"
                        :locale="rusCalendareLocale"
                        placeholder="Поиск"
                        @clear-click="selectedFilter"
                        @date-select="selectedFilter"
                    />
                </template>
            </Column>
            <Column
                header="Дата окончания"
                field="generatedAt"
                header-class="user-header-date table-header"
                body-class="user-body-date table-body"
                :sortable="true"
            >
                <template #body="slotProps">
                    <div v-if="slotProps.data.status !== 'scheduled'" class="p-text-nowrap p-text-truncate">
                        {{ slotProps.data.generatedAt | dateTime }}
                    </div>
                </template>
                <template #filter v-if="showFilters">
                    <Calendar
                        id="generatedAt"
                        v-model="generatedAt"
                        :showIcon="true"
                        :showOnFocus="true"
                        showButtonBar
                        dateFormat="dd.mm.yy"
                        :locale="rusCalendareLocale"
                        placeholder="Поиск"
                        @clear-click="selectedFilter"
                        @date-select="selectedFilter"
                    />
                </template>
            </Column>
            <Column
                header="Дата подписания"
                field="signedAt"
                header-class="user-header-date table-header"
                body-class="user-body-date table-body"
                :sortable="true"
            >
                <template #body="slotProps">
                    <div class="p-text-nowrap p-text-truncate">
                        {{ slotProps.data.signedAt | dateTime }}
                    </div>
                </template>
                <template #filter v-if="showFilters">
                    <Calendar
                        id="signedAt"
                        v-model="signedAt"
                        :showIcon="true"
                        :showOnFocus="true"
                        showButtonBar
                        dateFormat="dd.mm.yy"
                        :locale="rusCalendareLocale"
                        placeholder="Поиск"
                        @clear-click="selectedFilter"
                        @date-select="selectedFilter"
                    />
                </template>
            </Column>
            <Column
                header=""
                header-class="user-header-actions p-text-center"
                header-style="width: 52px;"
                body-class="user-body-actions"
                body-style="width: 52px; text-align: center;"
            >
                <template #body="slotProps">
                    <div
                        class="p-panel-header-icon p-link"
                        @click.stop="toggleRowMenu($event, slotProps)"
                        v-show="slotProps.data.isDownload"
                    >
                        <span class="pi pi-ellipsis-h"></span>
                    </div>
                    <Menu class="redLastListElement" :ref="`listMenu${slotProps.data.id}`" :model="listMenuItems" :popup="true" :baseZIndex="10" />
                </template>
                <template #header>
                    <Button icon="pi pi-filter" :class="filterClasses" class="p-button-rounded p-button-outlined filter-btn" @click="showFilters = !showFilters" />
                </template>
            </Column>
            <template #paginatorLeft>
                <div class='p-col-6'></div>
            </template>
        </DataTable>
        <signDocumentDialog
            v-if="currentReport"
            :showSignDocumentDialog="showSignDocumentDialog"
            :report="currentReport"
            @getReportsList="getReportsList"
            @closeSignDocumentDialog="closeSignDocumentDialog"
            @loadingChange="loadingChange"
        />
        <signInfoDialog
            v-if="currentReport.signedFile"
            :signInfo="currentReport"
            :showSignInfoDialog="showSignInfoDialog"
            @closeSignInfoDialog="closeSignInfoDialog"
        />
    </div>
</template>

<script>
import { getFormReports } from '@/api/form/reportTemplates';
import { getAllForms } from '@/api/form';
import { getUsers } from '@/api/user';
import { REPORT_STATUSES } from '@/constants/reports';
import { mapGetters } from 'vuex';
import { requestToastHandler } from '@/main/mixins';
import { downloadURI, jsonApiListParser } from '@/main/utils/common';
import { rusCalendareLocale } from '@/main/calendareLocales';
import { DEFAULT_PAGE_SIZE } from '@/constants/common';
import signDocumentDialog from '@/components/uploadingReports/dialogs/signDocumentDialog.vue';
import signInfoDialog from '@/components/uploadingReports/dialogs/signInfoDialog.vue';

export default {
    name: 'unloadingReports',

    mixins: [ requestToastHandler ],

    components: {
        signDocumentDialog,
        signInfoDialog
    },

    data() {
        this.reportStatuses = REPORT_STATUSES;
        this.pageSize = DEFAULT_PAGE_SIZE;
        this.rusCalendareLocale = rusCalendareLocale;

        return {
            loading: false,
            showSignDocumentDialog: false,
            showSignInfoDialog: false,
            currentReport: {},
            showFilters: false,
            formList: null,
            userList: null,
            filters: {},
            filterTimeout: null,
            sortField: '',
            reports: [],
            currentPage: 1,
            totalRecords: null,
            createdAt: null,
            generatedAt: null,
            signedAt: null
        };
    },
    methods: {
        onPage({ page, rows }) {
            this.currentPage = page + 1;
            this.pageSize = rows;
            this.debouncedFilter();
        },

        onSort({ sortField, sortOrder }) {
            this.sortField = `${ sortOrder > 0 ? '' : '-' }${ sortField }`;
            this.debouncedFilter();
        },

        rowDblClickHandler(event) {
            this.currentReport = event.data;

            if (this.currentReport.isDownload) {
                this.downloadReport();
            }
        },

        debouncedUserFilter({ value }) {
            clearTimeout(this.filterTimeout);

            this.filterTimeout = setTimeout(() => {
                this.updateUserList(value);
            }, 1000);
        },

        async updateUserList(value) {
            try {
                let filter = {};

                if (value.length > 0) {
                    filter = {
                        $or: [
                            {
                                'name.firstName': { $ilike: value }
                            },
                            {
                                'name.lastName': { $ilike: value }
                            }
                        ]
                    };
                }

                const { data, included } = await getUsers({
                    filter,
                    include: true,
                    pageSize: DEFAULT_PAGE_SIZE
                })
                this.userList = jsonApiListParser(data, included).map((user) => {
                    user.option = user.firstName ? `${ user.firstName } ${ user.lastName } (${ user.login })` : `${ user.login }`;
                    return user;
                });
            } catch (error) {
                this.$requestError(error.message);
            } finally {
                this.$emit('loadingChange');
            }
        },

        selectedFilter() {
            this.currentPage = 1;
            this.pageSize = DEFAULT_PAGE_SIZE;
            this.debouncedFilter();
        },

        debouncedFilter() {
            clearTimeout(this.filterTimeout);

            this.filterTimeout = setTimeout(() => {
                this.getReportsList();
            }, 1000);
        },

        downloadReport() {
            const ext = this.currentReport.fileUri?.split('.').pop();

            downloadURI(this.currentReport.title, ext, this.currentReport.fileUri);
        },

        toggleRowMenu(event, slotProps) {
            const { data: { id } } = slotProps;
            if (id !== this.currentReport.id) {
                this.$refs[`listMenu${ this.currentReport.id }`] && this.$refs[`listMenu${ this.currentReport.id }`].hide(event);
            }
            this.currentReport = slotProps.data;
            this.$refs[`listMenu${ id }`].toggle(event);
        },

        async getReportsList() {
            try {
                this.loading = true;

                let filter = {};
                if (this.filters.name && this.filters.name.length > 0) {
                    filter['name'] = { '$ilike': this.filters.name };
                }
                if (this.filters.form) {
                    filter['form.id'] = { '$eq': this.filters.form.id };
                }
                if (this.filters.user) {
                    filter['blame.createdBy.id'] = { '$eq': this.filters.user.id };
                }
                if (this.filters.status) {
                    filter['status'] = { '$eq': this.filters.status.value };
                }

                const currentTimeZoneOffset = (new Date()).getTimezoneOffset() * 60000;
                if (this.createdAt) {
                    filter['blame.createdAt'] = { '$gte': `${ new Date(this.createdAt.getTime() - currentTimeZoneOffset).toISOString().substring(0, 10) }T00:00:00Z` };
                }

                if (this.generatedAt) {
                    filter['generatedAt'] = { '$gte':`${ new Date(this.generatedAt.getTime() - currentTimeZoneOffset).toISOString().substring(0, 10) }T00:00:00Z` };
                }

                if (this.signedAt) {
                    filter['signedAt'] = { '$gte':`${ new Date(this.signedAt.getTime() - currentTimeZoneOffset).toISOString().substring(0, 10) }T00:00:00Z` };
                }

                const { data, included, meta } = await getFormReports(this.currentPage, this.pageSize, filter, this.sortField);
                this.totalRecords = meta.pagination.total;

                this.reports = [];
                const reportsList = jsonApiListParser(data, included);
                reportsList.forEach((report) => {
                    let signedBy = included.find(item => item.id === report.signedBy.id);
                    this.reports.push({
                        id: report.id,
                        title: report.name,
                        form: report.form.name,
                        user: `${ report.createdBy.firstName } ${ report.createdBy.lastName }`,
                        status: report.status,
                        createdAt: report.createdAt,
                        generatedAt: report.generatedAt,
                        file: report.file,
                        fileUri: report.file?.uri,
                        isSigned: !report.signedAt,
                        isDownload: report.status === 'done' || report.file?.uri,
                        signedFile: report.signedFile.uri,
                        signatureInfo: report.signatureInfo,
                        signedAt: report.signedAt,
                        signedBy: report.signedBy,
                        signedOrganization: included.find(item => item.id === signedBy?.relationships?.organization?.data?.id)
                    });
                });
            } catch (error) {
                this.$requestError(error.message);
            } finally {
                this.loading = false;
            }
        },

        getStatus(slotProps) {
            return this.reportStatuses.find(item => item.value === slotProps.data.status) || {};
        },

        closeSignDocumentDialog() {
            this.showSignDocumentDialog = false;
        },

        closeSignInfoDialog() {
            this.showSignInfoDialog = false;
        },

        downloadSignDocument() {
            downloadURI(this.currentReport.title, 'sig', this.currentReport.signedFile);
        },

        loadingChange(payload = false) {
            this.$emit('loadingChange', payload);
        }
    },
    computed: {
        ...mapGetters(['rowsPerPageOptions']),

        filterClasses() {
            return this.showFilters ? '' : 'p-button p-component p-button-outlined';
        },

        listMenuItems() {
            return [
                {
                    label: 'Скачать исходный документ',
                    icon: 'pi pi-download',
                    command: () => {
                        this.downloadReport();
                    }
                },
                {
                    label: 'Подписать документ',
                    icon: 'icon custom-sign-key',
                    command: () => {
                        this.showSignDocumentDialog = true;
                    },
                    visible: this.currentReport.isSigned
                },
                {
                    label: 'Скачать откреплённую подпись',
                    icon: 'pi pi-download',
                    command: () => {
                        this.downloadSignDocument();
                    },
                    visible: !this.currentReport.isSigned
                },
                {
                    label: 'Информация о подписи',
                    icon: 'icon custom-sign-key',
                    command: () => {
                        this.showSignInfoDialog = true;
                    },
                    visible: !this.currentReport.isSigned
                }
            ];
        },
    },

    async mounted() {
        await Promise.all([
            this.getReportsList(),
            getAllForms({
                relationShip: true,
                filter: { archive: false, active: true }
            }).then(({ forms, included }) => {
                this.formList = jsonApiListParser(forms, included);
            }),
            getUsers({ include: true }).then(({ data, included }) => {
                this.userList = jsonApiListParser(data, included).map((user) => {
                    user.option = user.firstName ? `${ user.firstName } ${ user.lastName } (${ user.login })` : `${ user.login }`;
                    return user;
                });
            })
        ]);
    },
};
// 477
</script>

<style lang="scss" scoped>
.layout-content-wrapper .page-container .user-list tr .redLastListElement {
    width: 256px;
}

.p-datatable {
    &-customers {
        ::v-deep {
            .p-paginator-bottom {
                border-width: 1px 0 0 0 !important;
            }

            .p-menu {
                width: min-content;
                white-space: nowrap;
            }

            .p-menuitem-link {
                .empty {
                    width: 14px;
                    height: 14px;
                    margin-right: 7px;
                }
            }

            .user-header {
                &-title {
                    width: 12vw;
                }

                &-user {
                    width: 12vw;
                }

                &-date {
                    width: 9vw;
                }
            }
            .user-body {
                &-title {
                    width: 12vw;
                }

                &-user {
                    width: 12vw;
                }

                &-date {
                    width: 9vw;
                }
            }
        }
    }
}

.relative {
    position: relative;
}

.p-component-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
}

.pi-spinner {
    font-size: 2rem;
}

::v-deep .customDropDown {
    width: 225px;

    .p-dropdown-filter {
        max-width: 100%;
    }
    .p-dropdown-items-wrapper {
        max-height: 350px !important;
        max-width: 395px;

        .p-dropdown-item {
            div {
                white-space: normal !important;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }

    & .p-dropdown-filter-icon {
        right: 0.7rem;
        top: 10px;
    }
}

::v-deep .p-calendar-w-btn .p-datepicker-trigger {
    margin-right: -104px;
}

::v-deep .p-calendar {
    line-height: normal;
    height: 33px;
}
::v-deep .p-datepicker {
    width: 300px;

    .p-timepicker {
        height: 0 !important;

        div {
            display: none;
        }
    }
}
</style>
