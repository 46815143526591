<template>
    <div>
        <Dialog
            :visible.sync="showDialog"
            header=""
            position="center"
            :modal="true"
            :closeOnEscape="true"
            :closable="true"
            @update:visible="closeDialog"
        >
            <template #header>
                <h3 class="p-mb-0">Подписать документ</h3>
            </template>
            <div class="p-d-flex p-flex-column p-col-12 p-py-1 p-mb-0 p-px-0">
                <span v-if="!pluginIsReady" style="color: red;">Плагин не готов к работе, скачайте <a href="https://www.cryptopro.ru/products/cades/plugin" target="_blank">по ссылке</a>.</span>
            </div>
            <div class="p-d-flex p-flex-column p-col-12 p-py-1 p-mb-0 p-px-0">
                <label>Выберите сертификат*:</label>
                <Dropdown
                    v-model="v$.certificate.$model"
                    :options="certificates"
                    option-label="title"
                    placeholder="Выберите сертификат"
                    :class="v$.certificate.$invalid && submitted ? 'p-error' : ''"
                />
                <small
                    v-show="submitted"
                    v-for="error in showErrors(v$.certificate)"
                    :key="error.$uid" class="p-error"
                >{{ error.$message }}</small>
            </div>
            <div class="p-d-flex p-flex-column p-col-12 p-py-1 p-mb-0 p-px-0" v-if="uploadFile">
                <label>Файл документа*:</label>
                <div class="p-d-flex p-jc-start p-ai-center uploadItem p-py-1">
                    <span>{{ uploadFile.name }}</span>
                    <input
                        :ref="`uploadItem${ uploadFile.id }`"
                        type="file"
                        class="uploadInput" />
                </div>
            </div>
            <div class="p-d-flex p-flex-column p-col-12 p-py-1 p-mb-0 p-px-0">
                <label style="font-weight: bold;">Информация о подписи:</label>
                <label>ФИО: {{ currentUser.lastName }} {{ currentUser.firstName }} {{ currentUser.secondName }}</label>
                <label>Организация: {{ currentUser.organization }}</label>
            </div>
            <template #footer>
                <div class="p-d-flex p-jc-start">
                    <Button class="p-button" @click="signDocument" :disabled="!uploadFile">
                        <span class="p-button-label">Подписать</span>
                    </Button>
                    <Button class="p-button p-button-outlined p-button-secondary" type="button" @click="closeDialog">
                        <span class="p-button-label">Отменить</span>
                    </Button>
                </div>
            </template>
        </Dialog>
    </div>
</template>

<script>
//import { axios } from '@/api/api.js';
import {requestToastHandler} from '@/main/mixins';
import {v4 as uuidv4} from 'uuid';
import {mapGetters} from 'vuex';
import cpa from '@/utils/cryptoProApi/index';
import {useVuelidate} from '@vuelidate/core';
import {required} from '@vuelidate/validators';
import {uploadFile} from '@/api/common';
import {signDocument} from '@/api/form/reportTemplates';

export default {
    name: 'signDocumentDialog',

    props: {
        showSignDocumentDialog: {
            require: true,
            type: Boolean
        },

        report: {
            type: Object,
            require: true
        }
    },

    mixins: [ requestToastHandler ],

    emits: [ 'loadingChange', 'closeSignDocumentDialog', 'getReportsList' ],

    data() {
        return {
            cryptoApi: null,
            certificate: null,
            uploadFile: null,
            showDialog: this.showSignDocumentDialog,
            submitted: false,
            pluginIsReady: false,
            certificates: []
        };
    },

    setup: () => ({ v$: useVuelidate() }),
    validations() {
        return {
            certificate: {
                required: {...required, $message: 'Поле обязательно к заполнению'},
            }
        }
    },

    async created() {
        this.cryptoApi = await cpa;
        const pluginInfo = await this.cryptoApi.about();
        this.pluginIsReady = !!pluginInfo;

        const certificates = await this.cryptoApi.getCertsList();
        this.certificates = certificates.map(cert => {
            const friendlySubjectInfo = cert.friendlySubjectInfo();
            const friendlyValidPeriod = cert.friendlyValidPeriod();
            const { to: { ddmmyy } } = friendlyValidPeriod;

            return {
                subjectInfo: friendlySubjectInfo,
                validPeriod: friendlyValidPeriod,
                thumbprint: cert.thumbprint,
                title: `${
                    friendlySubjectInfo.filter(el => el.value === 'Владелец')[0].text
                }. Сертификат действителен до: ${ddmmyy}`
            };
        });
    },

    methods: {
        async signDocument() {
            this.submitted = true;

            if (!this.uploadFile || this.v$.$invalid) {
                return;
            }

            this.$emit('loadingChange', true);

            let response = await fetch(this.uploadFile.file.downloadUri);
            let fileData = await response.blob();
            let newFile = new File([fileData], this.uploadFile.name, {
                type: this.uploadFile.file.mimeType
            });

            const result = await this.signFile(this.certificate.thumbprint, newFile);

            let blob = new Blob([result], {
                type: 'text/plain'
            });

            const fD = new FormData();
            fD.append('file', blob);
            const { data } = await uploadFile(fD);
            let signFile = data[0];

            const dataToServer = {
                data: {
                    signedFile: signFile.id,
                    signatureInfo: {
                        organization: this.currentUser.organization,
                        certificate: this.certificate.title
                    }
                }
            };

            try {
                const result = await signDocument(this.report.id, dataToServer);
                if (result.status === 200) {
                    this.$emit('getReportsList');
                }
            } catch (error) {
                this.$requestError(error.message);
            } finally {
                this.$emit('loadingChange');
            }

            this.closeDialog();
        },

        async signFile(certificate, file) {
            return new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    const header = ';base64,';
                    const fileData = reader.result;
                    const fileContent = fileData.substr(fileData.indexOf(header) + header.length);
                    resolve(this.cryptoApi.signFile(certificate, fileContent, false));
                };
            });
        },

        closeDialog() {
            this.showDialog = false;
            this.uploadFile = null;
            this.submitted = false;
            this.$emit('closeSignDocumentDialog');
        },

        showErrors(data) {
            return data.$errors.length ? data.$errors : data.$silentErrors;
        }
    },

    computed: {
        ...mapGetters('auth', [ 'currentUser' ])
    },

    watch: {
        showSignDocumentDialog: {
            handler(to) {
                if (to) {
                    this.showDialog = this.showSignDocumentDialog;
                    this.uploadFile = {
                        id: uuidv4(),
                        file: this.report.file,
                        name: `${ this.report.title }.xlsx`
                    };
                }
            }
        }
    }
}
</script>

<style scoped lang='scss'>
::v-deep .uploadItem {
    .p-button-raised,
    .p-button.p-button-danger:enabled:focus {
        box-shadow: none;
    }

    .fileNameLength {
        max-width: 210px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}

.uploadInput {
    display: none;
}

.uploadwidth {
    width: 164px;
}

::v-deep .p-fileupload-buttonbar {
    padding: 10px;

    span:nth-child(1) {
        margin-right: 12px;
    }

    button:nth-child(2) {
        display: none !important;
    }

    .p-button {
        box-shadow: none;
    }
}

::v-deep .p-dialog {
    min-width: 500px;
}
</style>
